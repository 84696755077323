body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@import './components/DialogPosition/DialogPosition.module.css';
@import './components/BuySellDialog/BuySellDialog.module.css';
@import './components/InfoBar/InfoBar.module.css';
@import './components/ChartTitle/ChartTitle.module.css';
