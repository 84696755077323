.infobar{
  padding: 10px 0 0 10px;
  margin: 10px 0 0 10px;
}

.infobarsmall{
  padding: 0px 0 0 0px;
  margin: 0px 0 0 0px;
}


.notifsmall {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgb(17, 85, 11);
    height: 100px;
    border-radius: 10px;
}

.notifbar {
    color: white;
    display: flex;
    font-size: 32px;
    font-weight: 200;
    text-transform: uppercase;
    align-items: center;
    width: 100%;
}

.fullname{
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: 200;
  text-transform: lowercase;
  align-items: center;
  width: 100%;
}

.fullnamesmall{
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: 200;
  text-transform: lowercase;
  align-items: center;
  width: 100%;

}


.clogo{
    font-size: 32px;
      font-weight: 600;
      text-transform: uppercase;
      align-items: center;
    color:var(--positive-buy-color);
    
  }
  
  .clogosmall{
    font-size: 28px;
      font-weight: 600;
      text-transform: uppercase;
      align-items: center;
    color:var(--positive-buy-color);
  

  }

  .alllogo{
    box-shadow: inset 0 0 0 0px var(--negative-sell-color),
        0px 0px 22px 0px #4d2b02 inset,
        0px 0px 20px 10px #552f01;

        width: fit-content;
        border-radius: 10px;
        
      
  }

  .infotext{
    font-size: 14px;
    color:var(--main-bb-color);

  }

  @-webkit-keyframes borderBlink {
    from,
    to {
      border-color: transparent
    }
    50% {
      border-color:white;
    }
  }
  
  @keyframes borderBlink {
    from,
    to {
      border-color: transparent
    }
    50% {
      border-color:white;
    }
  }
  
  .borderBlink {
    border: 3px solid white;
    /* add 'border-color: transparent' if you wish no border to show initially */
  
    -webkit-animation: borderBlink 2s step-end infinite;
      animation: borderBlink 2s step-end infinite;
  }
  
  .walletConnector {
    position: absolute;
    top: 15px;
    height: 55px;
    right:35px;
    display: flex;
    align-items: left;
    align-content: left;
    padding: 0px;
    width-max: 30%;
    z-index: 100;
  }
  
  .connect-button-browser {
    padding: 0px;
    color: black;
    font-weight: bolder;
    background: var(--main-bb-color);
    border-radius: 10px;
    border-color: var(--main-bb-color);
  }
  

  .walletConnectorSmall{
    position: absolute;
    bottom: 5px;
    height: 55px;
    right:5px;
    display: flex;
    align-items: left;
    align-content: left;
    padding: 0px;
    width-max: 30%;
    z-index: 100;
  }


.btnhelp{
  background-color:var(--main-bb-color);
  border: none;
  color: black;
  padding: 5px 5px 0px 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  font-weight: bolder;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 10px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
 
}