.maincont{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    

    color: aqua;
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
    padding: 20px;

}

.maincontsmall{
    position: absolute;
    top: 0;
    left: 0;
    
    width: auto;
    height: auto;
    

    color: aqua;
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
    padding: 20px;
    margin: 5px;
}


.getStartedButton{
    background-color:var(--main-bb-color);
    border: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
    justify-content: center;
  align-items: center;
    
}

.welcomeDialogFooter{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactdiv{


color: white;
background-color: black;

}

.xlogo
{
    width: 25px;
    height: 25px;


}